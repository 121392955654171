<template>
  <div class="site-header__btns" v-show="timeTresholdPassed">
    <button
      v-if="signerInstalled"
      v-on:click="initLogin"
      class="trn btn btn-outline-primary btn-color-bg-t rounded-pill font-14 font-sm-12"><span>Login</span></button>
    <button
      v-else
      v-on:click="requestInstall"
      class="trn btn btn-outline-primary btn-color-bg-t rounded-pill font-14 font-sm-12"><span>Install Signer</span></button>
  </div>
</template>

<script>
import { sha256 } from 'js-sha256';

const extensionId = "egckimjdlmhmpncflajekepildmicfoc";

function waitForGlobal(vm, key, checking, callback) {
  if (window[key]) {
    console.log("waitForGlobal: delete interval");
    clearInterval(vm.checkGlobalInterval);
    callback(true);
  } else if (!checking) {
    var expireAfterMillis = 5000;
    var tryIntervals = 100;
    var i = -1;
    console.log("waitForGlobal: create new interval interval");
    vm.checkGlobalInterval = setInterval(function() {
      i++;
      if ( tryIntervals * i < expireAfterMillis) {
        console.log("waitForGlobal: check again");
        waitForGlobal(vm, key, true, callback);
      } else {
        console.log("waitForGlobal: delete interval");
        clearInterval(vm.checkGlobalInterval);
        callback(false);
      }
    }, 100);
  }
}

function checkExtensionPresence(callback) {
  var img;
  img = new Image();
  img.src = "chrome-extension://" + extensionId + "/icons/favicon-16x16.png";
  img.onload = function() {
    callback(true);
  };
  img.onerror = function() {
    callback(false);
  };
}

function detectExtensionInstall(callback) {
  setInterval(function() {
    // console.log("detectExtensionInstall: check icon");
    checkExtensionPresence(function(result) {
      if (result) {   // it is avaibale
        callback();
      }
    });
  }, 1000);
}

export default {
  name: 'LoginButton',
  data: () => {
    return {
      timeTresholdPassed: false,
      signerInstalled: false,
      publicKey: null,
      message: null,
      signature: null,
      timestamp: null,
      checkGlobalInterval: null,
    }
  },
  methods: {
    detectBrowser: function () {
      var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== 'undefined';

      // Safari 3.0+ "[object HTMLElementConstructor]"
      var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

      // Internet Explorer 6-11
      var isIE = (navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true );

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;

      // Chrome 1 - 79
      var isChrome = !!window.chrome && navigator.userAgent.indexOf("Chrome") != -1;

      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

      if ( isOpera ) {
          return 'Opera';
      } else if( isChrome ) {
          return 'Chrome';
      } else if( isSafari ) {
          return 'Safari';
      } else if( isFirefox ){
          return 'Firefox';
      } else if( isIE ) {
          return 'IE';//crap
      } else if( isEdge ) {
          return 'Edge';//crap
      } else {
          return 'Unknown';
      }
    },

    initLogin: function() {
      var vm = this;
      this.timestamp = Math.floor(Date.now() / 1000);
      const hostname = window.location.hostname;
      const messageHash = sha256(`${hostname}(${this.timestamp})`);

      try {
        window.whcypher.signIn(
          messageHash,
          this.signatureSuccess,
          this.signatureFailure,
        );
      } catch (err) {
        this.signatureFailure(err);
      }
    },

    requestInstall: function() {
      var vm = this;

      const browser = this.detectBrowser();

      if (browser == 'Chrome' || browser == 'Opera') {
        window.open('https://chrome.google.com/webstore/detail/wh-cypher-beta/'+extensionId+'?hl=en-GB&authuser=0', '_blank');
      } else if ( browser == 'Firefox' ) {
        $.pageErrorMessage('FireFox will be supported shortly. Please try Chrome for now.');
      } else {
        $.pageErrorMessage('You browser currently is not supported. Please try with Chrome');
      }
    },

    signatureSuccess: function(context, callback) {
      const { pubKey, messageHash, signature } = context;
      const resultCallback = callback;

      $.ajax({
        url: "/user_sessions.json",
        type: "POST",
        data: {
          timestamp: this.timestamp,
          session: {
            public_key: pubKey,
            message_hash: messageHash,
            signature,
          }
        },
        timeout: 5000,
        success: function(response, textStatus) {
          resultCallback("success");
          window.location.reload();
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          resultCallback("failed");
          alert("Error: " + errorThrown);
        }
      });
    },

    signatureFailure: function(err) {
      $.pageErrorMessage("Error: " + err);
    },
  },
  mounted() {
    const vm = this;

    setTimeout(() => {
      vm.timeTresholdPassed = true;
    }, 1000);

    if ( !isMobile() ) {
      console.log("Login button: not mobile");
      waitForGlobal(vm, "whcypher", false, (result) => {
        if (result) {
          console.log("waitForGlobal callback: signer available");
          vm.signerInstalled = !!window.whcypher;
        } else {
          detectExtensionInstall(function () {
            console.log("detectExtensionInstall callback: signer detected");
            $.extensionInstalledMessage();
          })
        }
      });
    }
  }
}
</script>
