/* eslint no-console: 0 */
import Vue from 'vue/dist/vue.esm'
import LoginButton from '../components/login_button'

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    template: "<LoginButton />",
    components: {
      LoginButton
    },
  }).$mount('#login_button_wrapper');
})
